import styled from "styled-components"

const Content = styled.div`
  width: ${props => (props.fifty ? "50%" : "40%")};
  z-index: 1;

  @media (max-width: 800px) {
    width: 60%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`

export default Content
