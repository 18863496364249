import React from "react"
import styled from "styled-components"
import BackgroundTitle from "./background-title"
import Content from "./home-content"
import Copy from "./home-copy"
import { colors } from "../style/colors"
import { navigate } from "gatsby"
import "./background-title.css"
import Fade from "react-reveal/Fade"

const Container = styled.section`
  background-color: ${colors.red};
  background-color: ${props => props.alt && "white"};
  position: relative;
  scroll-snap-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 3%;
  align-items: flex-end;
  justify-content: center;
  /* padding: 50px; */
  @media (max-width: 600px) {
    align-items: center;
    padding: 10px 40px;
  }
`

const Button = styled.button`
  background: black;
  color: white;
  font-family: "Recursive";
  font-variation-settings: "wght" 500, "PROP" 1, "XPRN" 1;
  text-transform: uppercase;
  border: none;
  font-size: 30px;
  padding: 26px 15px;
  cursor: pointer;
  transition: 0.3s ease;

  :hover {
    font-variation-settings: "wght" 900, "PROP" 1, "XPRN" 1;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 600px) {
    font-size: 20px;
    padding: 20px 15px;
  }
`

class AboutSection extends React.Component {
  state = {
    setVisible: false,
    height: "360px",
  }

  render() {
    return (
      <Container
        onTouchStart={() => this.setState({ setVisible: true })}
        onMouseOver={() => this.setState({ setVisible: true })}
        onMouseEnter={() => this.setState({ setVisible: true })}
        alt={this.props.alt}
      >
        <BackgroundTitle
          visible={this.state.setVisible}
          top
          left
          title="About Me"
          yellow={this.props.alt}
        />
        <Content fifty>
          <Fade delay={1000}>
            <Copy dangerouslySetInnerHTML={{ __html: this.props.copy }} />
          </Fade>
          <Fade delay={1100}>
            <Button onClick={() => navigate("/about")}>Read More</Button>
          </Fade>
        </Content>
      </Container>
    )
  }
}

export default AboutSection
