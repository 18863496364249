import React from "react"
import styled from "styled-components"
import BackgroundTitle from "./background-title"
import Content from "./home-content"
import Copy from "./home-copy"
import Fade from "react-reveal/Fade"
import { navigate } from "gatsby"
import Footer from "./footer"

const Container = styled.section`
  background: rgb(15, 188, 188);
  background: linear-gradient(
    180deg,
    rgba(15, 188, 188, 1) 0%,
    rgba(15, 188, 188, 1) 86%,
    rgba(255, 255, 255, 1) 100%
  );
  background: ${props => props.alt && "white"};
  position: relative;
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  padding: 5%;
  align-items: flex-end;
  justify-content: center;
  /* padding: 50px; */
  @media (max-width: 600px) {
    align-items: center;
    padding: 20px 40px;
  }
`

const Button = styled.button`
  background: black;
  color: white;
  font-family: "Recursive";
  font-variation-settings: "wght" 500, "PROP" 1, "XPRN" 0;
  text-transform: uppercase;
  border: none;
  font-size: 30px;
  padding: 26px 15px;
  transition: 0.3s ease;
  cursor: pointer;

  :hover {
    font-variation-settings: "wght" 900, "PROP" 1, "XPRN" 1;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 600px) {
    font-size: 20px;
    padding: 20px 15px;
  }
`

class HowSection extends React.Component {
  state = {
    setVisible: false,
  }
  render() {
    return (
      <>
        <Container
          onTouchStart={() => this.setState({ setVisible: true })}
          onMouseOver={() => this.setState({ setVisible: true })}
          onMouseEnter={() => this.setState({ setVisible: true })}
          alt={this.props.alt}
        >
          <BackgroundTitle
            visible={this.state.setVisible}
            left
            title="How I Do It"
            yellow={this.props.alt}
          />
          <Content fifty>
            <Fade delay={1000}>
              <Copy dangerouslySetInnerHTML={{ __html: this.props.copy }} />
            </Fade>
            <Fade delay={1100}>
              <Button onClick={() => navigate("/work")}>Read More</Button>
            </Fade>
          </Content>
        </Container>
        <Footer />
      </>
    )
  }
}

export default HowSection
