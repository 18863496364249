import React from "react"
import styled, { keyframes } from "styled-components"
import Fade from "react-reveal/Fade"

const bigger = keyframes`
  0% {opacity: 1; font-variation-settings: "wght" 100, "wdth" 70, "PROP" 1, "XPRN" 1, "CASL" 1; }
  50% {opacity: 1; font-variation-settings: "wght" 900, "wdth" 70, "PROP" 1, "XPRN" 1, "CASL" 1; }
  100% {opacity: 1; font-variation-settings: "wght" 100, "wdth" 70, "PROP" 1, "XPRN" 1, "CASL" 1; }
`
const grow = keyframes`
  0% {
    font-variation-settings: "wght" 330, "PROP" 1, "XPRN" 1, "CASL" 1;
  }

  50% {
    font-variation-settings: "wght" 900, "PROP" 1, "XPRN" 1, "CASL" 1;
  }

  100% {
    font-variation-settings: "wght" 800, "PROP" 1, "XPRN" 1, "CASL" 1;
  }
`
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const Container = styled.section`
  display: flex;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: black;
  scroll-snap-align: center;
`
const Content = styled.div`
  z-index: 1;
  opacity: 0;
  animation: ${fadeIn} 1s ease 0.5s 1 forwards;
`
const Title = styled.h1`
  color: ${props => props.color};
  font-size: 18vw;
  line-height: 0.9;
  margin: 0;
  pointer-events: none;

  span {
    animation: ${grow} forwards;
    animation-duration: 0.125s;
    animation-timing-function: ease-out;
    animation-delay: 0.4s;
    font-family: "Recursive";
    font-variation-settings: "wght" 330, "PROP" 1, "XPRN" 1, "CASL" 1;
    letter-spacing: -9px;

    @media (max-width: 600px) {
      letter-spacing: -3px;
    }
  }
  span:nth-child(2) {
    animation-delay: 0.4s;
  }
  span:nth-child(3) {
    animation-delay: 0.5s;
  }
  span:nth-child(4) {
    animation-delay: 0.6s;
  }
  span:nth-child(5) {
    animation-delay: 0.7s;
  }
  span:nth-child(6) {
    animation-delay: 0.8s;
  }
  span:nth-child(7) {
    animation-delay: 0.9s;
  }
  span:nth-child(8) {
    animation-delay: 1s;
  }
  span:nth-child(9) {
    animation-delay: 1.1s;
  }
  span:nth-child(10) {
    animation-delay: 1.2s;
  }
  span:nth-child(11) {
    animation-delay: 1.3s;
  }
  span:nth-child(12) {
    animation-delay: 1.4s;
  }
  span:nth-child(13) {
    animation-delay: 1.5s;
  }
  span:nth-child(14) {
    animation-delay: 1.6s;
  }
`
const Subtitle = styled.p`
  font-family: "Recursive";
  text-transform: uppercase;
  font-variation-settings: "wght" 600, "PROP" 1, "XPRN" 0;
  font-size: 20px;
  color: white;

  margin: 30px 0 0 0;

  @media (max-width: 768px) {
    max-width: 200px;
    margin: 50px auto;
    text-align: center;
    padding: 20px;
  }
`
const Arrows = styled.p`
  color: ${props => props.color};
  opacity: 1;
  animation: ${fadeOut} 0.3s ease 10s forwards;

  span {
    font-size: 50px;
    margin: 0 10px;
    animation: ${bigger} infinite;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-delay: 5s;
    animation-iteration-count: 3;
    font-variation-settings: "wdth" 70, "PROP" 1, "XPRN" 1;
    opacity: 0;
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
    animation-delay: 5.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.3s;
    animation-delay: 5.3s;
  }
`
const ArrowContainer = styled.div`
  position: absolute;
  bottom: 5vh;
  left: 0;
  right: 0;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease 5.2s forwards;
`
class SiteIntro extends React.Component {
  state = {
    mobile: null,
  }
  componentWillMount() {
    if (typeof window !== "undefined") {
      let vw = window.innerWidth

      if (vw < 768) {
        //In a mobile view, shoowwww the background title
        this.setState({ mobile: true })
      }
    }
  }

  render() {
    const { mobile } = this.state
    const titleColors = ["#F9ED24", "#ff8345", "#0FBCBC", "#EFE1BA"]
    const selectedColor =
      titleColors[Math.floor(Math.random() * titleColors.length)]

    if (mobile) {
      return (
        <Container>
          {/* <Fade delay={300}> */}
          <Content>
            <Title color={titleColors[Math.floor(Math.random() * titleColors.length)]}>
              <span>R</span>
              <span>o</span>
              <span>c</span>
              <span>h</span>
              <span>e</span>
              <span>l</span>
              <span>l</span>
              <span>e</span>
              <Subtitle>KNOWLEDGE TRANSLATOR & IMPACT-MAKER</Subtitle>

              <span>A</span>
              <span>m</span>
              <span>o</span>
              <span>u</span>
              <span>r</span>
            </Title>
            {/* <LearnMore>Learn More</LearnMore> */}
          </Content>
          {/* </Fade> */}
          <ArrowContainer>
            <Arrows color={titleColors[Math.floor(Math.random() * titleColors.length)]}>
              <span>↓</span>
              <span>↓</span>
              <span>↓</span>
            </Arrows>
          </ArrowContainer>
        </Container>
      )
    }

    if (!mobile) {
      return (
        <Container>
          <Content>
            <Title color={titleColors[Math.floor(Math.random() * titleColors.length)]}>
              <span>R</span>
              <span>o</span>
              <span>c</span>
              <span>h</span>
              <span>e</span>
              <span>l</span>
              <span>l</span>
              <span>e</span>
              <span> </span>
              <span>A</span>
              <span>m</span>
              <span>o</span>
              <span>u</span>
              <span>r</span>
            </Title>
            <Subtitle>IS A KNOWLEDGE TRANSLATOR & IMPACT-MAKER</Subtitle>
            {/* <LearnMore>Learn More</LearnMore> */}
          </Content>
          <ArrowContainer>
            <Arrows color={titleColors[Math.floor(Math.random() * titleColors.length)]}>
              <span>↓</span>
              <span>↓</span>
              <span>↓</span>
            </Arrows>
          </ArrowContainer>
        </Container>
      )
    }

    return <Container></Container>
  }
}

export default SiteIntro
