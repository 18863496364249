import styled from "styled-components"

const Copy = styled.p`
  font-size: 25px;
  line-height: 32px;
  color: ${props => (props.white ? "#FFFFFF" : "#000000")};

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 29px;
  }
`

export default Copy
