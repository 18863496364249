import React from "react"
import styled from "styled-components"
import BackgroundTitle from "./background-title"
import Content from "./home-content"
import Copy from "./home-copy"
import { colors } from "../style/colors"
import { navigate } from "gatsby"
import Fade from "react-reveal/Fade"

const Container = styled.section`
  position: relative;
  background-color: black;
  scroll-snap-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 5%;
  align-items: flex-start;
  justify-content: center;
  /* padding: 50px; */

  @media (max-width: 600px) {
    align-items: center;
    padding: 20px 40px;
  }
`

const Button = styled.button`
  background: ${colors.yellow};
  color: black;
  font-family: "Recursive";
  font-variation-settings: "wght" 500, "PROP" 1, "XPRN" 1;
  text-transform: uppercase;
  border: none;
  font-size: 30px;
  padding: 26px 15px;
  transition: 0.3s ease;
  cursor: pointer;

  :hover {
    font-variation-settings: "wght" 900, "PROP" 1, "XPRN" 1;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 600px) {
    font-size: 20px;
    padding: 20px 15px;
  }
`

class WhyMeSection extends React.Component {
  state = {
    setVisible: false,
  }
  render() {
    return (
      <Container
        onTouchStart={() => this.setState({ setVisible: true })}
        onMouseOver={() => this.setState({ setVisible: true })}
        onMouseEnter={() => this.setState({ setVisible: true })}
      >
        <BackgroundTitle
          visible={this.state.setVisible}
          yellow
          right
          title="Why Me?"
        />
        <Content>
          <Fade delay={1000}>
            <Copy white dangerouslySetInnerHTML={{ __html: this.props.copy }} />
          </Fade>
          <Fade delay={1100}>
            <Button onClick={() => navigate("/work")}>View Services</Button>
          </Fade>
        </Content>
      </Container>
    )
  }
}

export default WhyMeSection
